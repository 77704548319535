import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container } from './artists.css';
import Title from 'components/title';
import Instagram from '../../images/instagram.svg';
import Spotify from '../../images/spotify.svg';

const ArtistsList = () => {
  const data = useStaticQuery(graphql`
    query HomeartistsQuery {
      allContentfulGalleryItems(sort: { order: ASC, fields: artistName }) {
        edges {
          node {
            spotifyUrl
            instagramUrl
            artistName
            artistImage {
              title
              sizes(maxWidth: 600, maxHeight: 450, quality: 90) {
                ...GatsbyContentfulSizes_withWebp
              }
              id
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Container>
        {data.allContentfulGalleryItems.edges.map(edge => (
          <Fragment key={edge.node.id}>
            <div className="artist-item">
              <figure>
                <Img
                  sizes={edge.node.artistImage.sizes}
                  alt={edge.node.artistImage.title}
                />
                <div className="socials">
                  <div className="icons">
                    <a
                      href={edge.node.spotifyUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="open-spotify"
                    >
                      <span className="icon">
                        <Spotify />
                      </span>
                    </a>
                    <a
                      href={edge.node.instagramUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="open-instagram"
                    >
                      <span className="icon">
                        <Instagram />
                      </span>
                    </a>
                  </div>
                </div>
                <Title as="h3">{edge.node.artistName}</Title>
              </figure>
            </div>
          </Fragment>
        ))}
      </Container>
    </>
  );
};

export default ArtistsList;
