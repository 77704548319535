import React from 'react';
import Layout from 'components/layout';
import HomeIntro from 'components/homeintro';
import ArtistsList from 'components/artists';

const Index = () => (
  <Layout>
    <HomeIntro />
    <ArtistsList />
  </Layout>
);

export default Index;
