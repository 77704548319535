import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  max-width: 100%;
  margin: 0px auto;
  padding: 0 40px;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '400';
      default:
        return '500';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem';
      default:
        return '2rem';
    }
  }};

  ${MEDIA.SMALLDESKTOP`
    grid-template-columns: 1fr;
  `};

  ${MEDIA.DESKTOP`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        default:
          return '2rem';
      }
    }};
  `};

  a {
    text-decoration: none;
    font-size: 1.8rem;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -4px;
      left: 0;
      background-color: white;
    }
  }
`;

export const Toplabel = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: #7c7c7c;
  margin-bottom: 20px;
  display: block;
`;

export const Maintext = styled.p`
  font-size: 2.7rem;
  line-height: 1.6;
  margin-bottom: 60px;
  ${MEDIA.TABLET`
    font-size: 2.3rem;
  `};
`;

export const Copywrapper = styled.div`
  padding: 7rem 14rem 25rem 0rem;
  grid-area: 1 / 2 / 2 / 3;
  ${MEDIA.TABLET`
    padding: 7rem 7rem 25rem 0rem;
  `};
`;
