import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Container, Copywrapper, Toplabel, Maintext } from './homeintro.css';

const HomeIntro = () => {
  const data = useStaticQuery(graphql`
    query HomeintroQuery {
      allContentfulHomeIntro {
        edges {
          node {
            mainText {
              mainText
            }
            topLabel
            id
          }
        }
      }
    }
  `);

  return (
    <>
      {data.allContentfulHomeIntro.edges.map(edge => (
        <Fragment key={edge.node.id}>
          <Container>
            <Copywrapper>
              <Toplabel>{edge.node.topLabel}</Toplabel>
              <Maintext>{edge.node.mainText.mainText}</Maintext>
              <Link to="/about">More about us</Link>
            </Copywrapper>
          </Container>
        </Fragment>
      ))}
    </>
  );
};

export default HomeIntro;
