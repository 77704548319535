import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 0 20px;
  max-width: 100%;
  margin: 0 auto;
  ${MEDIA.DESKTOP`
    grid-template-columns: repeat(3, 1fr);
  `};

  ${MEDIA.SMALLDESKTOP`
    grid-template-columns: repeat(2, 1fr);
  `};
  ${MEDIA.TABLET`
    grid-template-columns: repeat(1, 1fr);
  `};
  .artist-item {
    figure {
      position: relative;
      display: grid;
      overflow: hidden;
      h3 {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-transition: bottom 0.2s ease-in;
        -moz-transition: bottom 0.2s ease-in;
        -o-transition: bottom 0.2s ease-in;
        -ms-transition: bottom 0.2s ease-in;
        transition: bottom 0.2s ease-in;
      }
      &:hover > .socials {
        opacity: 1;
      }
      &:hover > h3 {
        bottom: -100px;
      }
      .socials {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        align-items: center;
        justify-items: center;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        text-decoration: none;
        -webkit-transition: opacity 0.5s ease-in;
        -moz-transition: opacity 0.5s ease-in;
        -o-transition: opacity 0.5s ease-in;
        -ms-transition: opacity 0.5s ease-in;
        transition: opacity 0.5s ease-in;
        a {
          &:nth-child(1) {
            margin-right: 15px;
          }
        }
      }
    }
  }
`;
